<template>
  <div style="min-height: calc(100vh - 48px)">
<!--    <CustomerCrmTopMenu />-->
    <router-view></router-view>
  </div>
</template>

<script>
import CustomerCrmTopMenu from "../../../components/Customer/CustomerCrmTopMenu";

export default {
  components: {CustomerCrmTopMenu}
}
</script>
