<template>
  <v-row>
    <v-col cols="12" class="mb-6 ml-3">
<!--      <h2 class="mt-6  title">{{ $t('employee.crm.title') }}</h2>-->
      <div class="mt-6 mr-5 crm-topmenu">
        <v-btn :to="{name: 'crm.customer'}" class="rounded-0" elevation="1">
          {{ $t('customer.crm.buttons.customers') }}
        </v-btn>
<!--        <v-btn :to="{name: 'crm.reports'}" class="rounded-0" elevation="1">-->
<!--          {{ $t('employee.crm.buttons.report') }}-->
<!--        </v-btn>-->
        <v-btn class="rounded-0" elevation="1">
          {{ $t('customer.crm.buttons.role_and_permissions') }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {}
</script>

<style lang="scss">
.crm-topmenu {
  .v-btn--active {
    background-color: #fc8b5d !important;
    &::before {
      opacity: 0 !important;
    }
  }
}
</style>
